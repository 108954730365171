window.addEventListener('DOMContentLoaded', function () {
  const menuButton = document.getElementById('menu-button');
  const menu = document.getElementById('menu-contents');
  const nav = document.getElementById('nav');
  const body = document.documentElement;

  const cancelEvent = function (e) {
    e.preventDefault();
  };


  menuButton.addEventListener('click', function (e) {
    e.preventDefault();
    const hiding = menu.classList.contains('visible');

    if (hiding) {
      document.removeEventListener('touchmove', cancelEvent);
    } else {
      document.addEventListener('touchmove', cancelEvent, { passive: false });
    }

    menu.classList.toggle('visible');
    body.classList.toggle('no-scroll');
  });

  window.addEventListener('scroll', function () {
    window.requestAnimationFrame(function () {
      if (body.scrollTop > 0) {
        nav.classList.add('stuck');
      } else {
        nav.classList.remove('stuck');
      }
    });
  });
});
